import {
  CarouselMarca1,
  CarouselMarca2,
  CarouselMarca3,
  CarouselMarca4,
  CarouselMarca5,
} from "../images";

export const brands = [
  CarouselMarca1,
  CarouselMarca2,
  CarouselMarca3,
  CarouselMarca4,
  CarouselMarca5,
];
